
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import Filter from '@/interfaces/api/Filter';
import PrintGroupsTable from '@/components/printGroups/PrintGroupsTable.vue';

const venue = namespace('venue');
const printGroups = namespace('printGroups');

@Component({
  components: { PrintGroupsTable, VEmptyState, VWrapper },
})
export default class PrintGroups extends Vue {
  @venue.State('active') public venue!: Venue;

  @printGroups.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @printGroups.Action('fetch') public getPrintGroups!: any;
  @printGroups.Action('destroy') public destroyPrintGroups!: any;
  @printGroups.State((state) => state.pagination.total) public total!: number;

  @printGroups.State('items') public items!: PrintGroups[];

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      this.$startLoading('table');
      this.setFilter({ venue: this.venue._id });
      this.getPrintGroups({ page: 1 });
      this.$stopLoading('table');
    }
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      if (this.venue) {
        this.$startLoading('printGroups');
        this.setFilter({ venue: this.venue._id });
        this.getPrintGroups({ page: 1 });
        this.$stopLoading('printGroups');
      }
    }
  }

  public destroy(item: PrintGroups) {
    this.destroyPrintGroups(item);
  }
}
